@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  display:block;
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}