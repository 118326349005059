#background{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('./assets/img/background.png');
    background-position: bottom right;
    background-size: cover;
    z-index: -1;
}